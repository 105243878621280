import React from "react";
import styled from "@emotion/styled";
// components
import { Logo } from "../logo/logo";
// utils
import { Colors } from "../utils/colors";

export const Header = () => {
  return (
    <SHeader>
      <Logo />
    </SHeader>
  );
};

const SHeader = styled.header`
  padding: 0 2rem;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  @media screen and (max-width: 599px) {
    padding: 0 1rem;
  }
`;
