import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Colors } from "../utils/colors";

export const Logo = () => {
  return (
    <SLogo>
      <Link to="/" tabIndex="-1">
        KIWI
      </Link>
    </SLogo>
  );
};

const SLogo = styled.div`
  display: inline-flex;
  a {
    line-height: 1;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${Colors.primary};
  }
`;
