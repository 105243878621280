import React from "react";
import styled from "@emotion/styled";
// utils
import { Colors } from "../utils/colors";

export const Footer = () => {
  return (
    <SFooter>
      <p>このサイトはデモサイトです。</p>
      <p>Demand Link Co.,Ltd.</p>
    </SFooter>
  );
};

const SFooter = styled.footer`
  padding: 1rem 2rem;
  width: 100%;
  background-color: ${Colors.white};
  p {
    font-size: 0.9rem;
    text-align: center;
  }
`;
