import React from "react";
import { Helmet } from "react-helmet";
import Icon from "../../images/favicon.ico";

export const Seo = () => {
  return (
    <Helmet>
      <html lang="ja" />
      <title>KIWI | お問い合わせフォーム</title>
      <link rel="icon" type="image/png" href={Icon} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
      ></meta>
    </Helmet>
  );
};
